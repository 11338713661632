<template>
  <main :class="['main', ...classComputed]">
    <slot />
  </main>
</template>

<script setup lang="ts">
const $route = useRoute();

const classComputed = computed(() => {
  return [$route.meta.layout && `is-${$route.meta.layout}`];
});
</script>

<style lang="scss" src="./PageMain.scss" />
